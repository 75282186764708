import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import L from 'leaflet'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'

import '../../assets/styles/leaflet.css'
import origin from '../../assets/img/origin.png'
import marker_error from '../../assets/img/marker_error.png'
import courier_flag from '../../assets/img/map/_courier-flag.png'
import { showClient, hasPermission } from '../../utils'
import { permissionLevels } from '../../utils/variables'

const coordinatesFromString = strCoordinates => {
  if (!strCoordinates) return { lat: 0, lng: 0 }
  const coordinates = strCoordinates.replace(/ /g, '').split(',')
  const lat = parseFloat(coordinates[0])
  const lng = parseFloat(coordinates[1])
  return { lat, lng }
}

const statusColor = level => {
  switch (level) {
    case 'partial':
      return 'orange'
    case 'full':
      return 'red'
    default:
      return 'green'
  }
}

const getPosition = destinations => {
  if (destinations.length > 0) {
    const { lat, lng } = coordinatesFromString(destinations[0].latlon)
    return [lat, lng]
  }
  return [0, 0]
}

const getMarkerIcon = (type, extra) => {
  let iconUrl = origin
  let classNameIcon = ''
  let shadowSize = null

  if (type !== 'order') {
    const {
      busy_level,
      map_image,
      map_image_attributes,
      can_reject_orders
    } = extra
    iconUrl = map_image || marker_error
    classNameIcon = busy_level
    shadowSize = [map_image_attributes.width, map_image_attributes.height]

    const busyLevelColor = {
      partial: 'rgba(255, 165, 0, 0.8)',
      full: 'rgba(255, 0, 0, 0.8)',
      default: 'rgba(11, 156, 49, 0.8)'
    }

    const overlayColor = busyLevelColor[busy_level] || busyLevelColor.default

    if (can_reject_orders) {
      return L.divIcon({
        className: 'custom-marker',
        html: `
          <div style="position: relative; width: 30px; height: 55px;">
            <div style="position: absolute; top: -5px; left: 8px; width: 14px; height: 14px; background-color: ${overlayColor};"></div>
            <img src="${iconUrl}" style="width: 100%; height: 100%;" />
            <img src="${courier_flag}" style="position: absolute; top: -10px; right: -10px; width: 20px; height: 20px;" />
          </div>
        `,
        iconSize: [30, 55],
        iconAnchor: [15, 39],
        popupAnchor: [0, -41]
      })
    }

    const opt = {
      popupAnchor: [0, -41],
      tooltipAnchor: [10, -40],
      iconUrl,
      shadowSize,
      iconSize: [30, 55],
      iconAnchor: [15, 39],
      className: classNameIcon, // Aplicar la clase busy_level
      shadowUrl: '-'
    }

    const iconImg = L.icon(opt)

    if (extra.map_image_attributes) {
      iconImg.createShadow = function(oldIcon) {
        const img = this._createIcon('shadow', oldIcon)
        img.style.top = extra.map_image_attributes.top + 'px'
        img.style.left = extra.map_image_attributes.left + 'px'
        return img
      }
    }

    return iconImg
  }

  return L.icon({
    iconUrl,
    iconSize: [30, 33],
    iconAnchor: [15, 39],
    popupAnchor: [0, -41],
    tooltipAnchor: [10, -40],
    shadowUrl: '-'
  })
}

const crispId = process.env.REACT_APP_CRISP_ID

const LTrackingMap = React.memo(
  ({
    defaultCenter,
    zoom,
    orderActive,
    orderMapVisibility,
    courierData,
    onOrderUpdate,
    fnCourierClick
  }) => {
    const handleCourierClick = useCallback(
      courier => {
        fnCourierClick(courier)
      },
      [fnCourierClick]
    )
    const handleOrderUpdate = useCallback(
      (orderId, updates) => {
        onOrderUpdate(orderId, updates)
      },
      [onOrderUpdate]
    )

    const markers = useMemo(() => {
      if (!courierData) return []

      return courierData.map(courier => {
        if (courier.position && courier.current_vehicle) {
          return (
            <Marker
              key={courier.id}
              position={courier.position.coordinates}
              icon={getMarkerIcon('courier', {
                id: courier.current_vehicle.vehicle_type.id,
                busy_level: courier.busy_level,
                status: {
                  is_busy: courier.is_busy,
                  is_online: courier.is_online
                },
                map_image: courier.current_vehicle.vehicle_type.map_image,
                map_image_attributes:
                  courier.current_vehicle.vehicle_type.map_image_attributes,
                can_reject_orders: courier.can_reject_orders
              })}
            >
              <Popup>
                <div>
                  <div style={{ paddingBottom: '10px', textAlign: 'center' }}>
                    {!courier.current_vehicle.vehicle_type.id ? (
                      <p>
                        Este courier no tiene vehículo activo, favor reportar.
                      </p>
                    ) : null}
                    <Link
                      style={{
                        display: 'inline-block',
                        width: '100%',
                        marginBottom: '10px'
                      }}
                      to={`/couriers/history/${courier.id}`}
                    >
                      <img
                        className="chat-avatar"
                        style={{ float: 'none', margin: '0' }}
                        src={courier.photo}
                        alt=""
                      />
                    </Link>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => handleCourierClick(courier)}
                    >
                      <strong>
                        <i
                          className="fa fa-circle"
                          style={{
                            marginRight: '10px',
                            color: statusColor(courier.busy_level)
                          }}
                        />
                        {`${courier.name} ${courier.last_name}`}
                      </strong>
                    </button>
                    <br />
                    <span>{courier.phone}</span>
                    <br />
                    <small>{courier.status}</small>
                    <br />
                    {hasPermission('shop', permissionLevels.READ) && (
                      <a
                        href={`https://app.crisp.chat/website/${crispId}/contacts/profile/${courier.email}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ver en Crisp
                      </a>
                    )}
                  </div>
                  {orderActive && orderActive.status === 'searching' && (
                    <div>
                      <button
                        type="button"
                        className="btn btn-block btn-outline btn-primary btn-xs"
                        onClick={() =>
                          handleOrderUpdate(orderActive.id, {
                            courier_id: courier.id
                          })
                        }
                      >
                        Asignar pedido
                      </button>
                    </div>
                  )}
                </div>
              </Popup>
            </Marker>
          )
        }
        return null
      })
    }, [courierData, handleCourierClick, handleOrderUpdate, orderActive])

    return (
      <Map center={defaultCenter} zoom={zoom}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
        />
        {orderActive && orderMapVisibility && (
          <Marker
            key={orderActive.id}
            position={getPosition(orderActive.destinations)}
            icon={getMarkerIcon('order')}
          >
            <Popup>
              <div>
                <strong>{showClient(orderActive.client)}</strong>
                <p style={{ margin: 0 }}>{orderActive.status}</p>
                <p style={{ margin: 0 }}>
                  {orderActive.destinations.length > 0
                    ? orderActive.destinations[0].name_destination
                    : false}
                </p>
                <p style={{ margin: 0 }}>
                  {moment(orderActive.created_at).format('D MMM gg')}
                </p>
              </div>
            </Popup>
          </Marker>
        )}
        {markers}
      </Map>
    )
  }
)

export default LTrackingMap
