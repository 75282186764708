import $ from 'jquery'
import { CALL_API } from '../middleware/api'

// CANDIDATE GENERAL INFORMATION
export const CANDIDATE_LIST_REQUEST = 'CANDIDATE_LIST_REQUEST'
export const CANDIDATE_LIST_SUCCESS = 'CANDIDATE_LIST_SUCCESS'
export const CANDIDATE_LIST_FAILURE = 'CANDIDATE_LIST_FAILURE'

// CANDIDATE UPDATE DATA
export const UPDATE_CANDIDATE_FILTERS = 'UPDATE_CANDIDATE_FILTERS'

// CANDIDATE DELETE DATA
export const CANDIDATE_CHANGE_STATUS_REQUEST =
  'CANDIDATE_CHANGE_STATUS_REQUEST'
export const CANDIDATE_CHANGE_STATUS_SUCCESS =
  'CANDIDATE_CHANGE_STATUS_SUCCESS'
export const CANDIDATE_CHANGE_STATUS_FAILURE =
  'CANDIDATE_CHANGE_STATUS_FAILURE'

export const UPDATE_CANDIDATE_DETAIL_FILTERS =
  'UPDATE_CANDIDATE_DETAIL_FILTERS'
export const CANDIDATE_DETAIL_REQUEST = 'CANDIDATE_DETAIL_REQUEST'
export const CANDIDATE_DETAIL_SUCCESS = 'CANDIDATE_DETAIL_SUCCESS'
export const CANDIDATE_DETAIL_FAILURE = 'CANDIDATE_DETAIL_FAILURE'

// CANDIDATE DOCUMENT INFORMATION
export const CANDIDATE_DOCS_REQUEST = 'CANDIDATE_DOCS_REQUEST'
export const CANDIDATE_DOCS_SUCCESS = 'CANDIDATE_DOCS_SUCCESS'
export const CANDIDATE_DOCS_FAILURE = 'CANDIDATE_DOCS_FAILURE'

export const SELECT_CANDIDATE = 'SELECT_CANDIDATE'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const RESET_CANDIDATE = 'RESET_CANDIDATE'

export function candidateList(params = {}) {
  let url = 'courier_candidate/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        CANDIDATE_LIST_REQUEST,
        CANDIDATE_LIST_SUCCESS,
        CANDIDATE_LIST_FAILURE
      ]
    }
  }
}

export function updateCandidateFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_CANDIDATE_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function updateCandidateDetailFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_CANDIDATE_DETAIL_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function selectCandidate(obj) {
  return dispatch => {
    const action = {
      type: SELECT_CANDIDATE,
      data: obj
    }
    dispatch(action)
  }
}

export function changeStatusCandidate(data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `courier_candidate/${data.id}/`,
      authenticated: true,
      data,
      types: [
        CANDIDATE_CHANGE_STATUS_REQUEST,
        CANDIDATE_CHANGE_STATUS_SUCCESS,
        CANDIDATE_CHANGE_STATUS_FAILURE
      ]
    }
  }
}

export function getCandidateData(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `courier_candidate/${id}/`,
      authenticated: true,
      types: [
        CANDIDATE_DETAIL_REQUEST,
        CANDIDATE_DETAIL_SUCCESS,
        CANDIDATE_DETAIL_FAILURE
      ]
    }
  }
}

export function getCandidateDocs(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `courier_candidate/${id}/documents/`,
      authenticated: true,
      types: [
        CANDIDATE_DOCS_REQUEST,
        CANDIDATE_DOCS_SUCCESS,
        CANDIDATE_DOCS_FAILURE
      ]
    }
  }
}
