import $ from 'jquery'

export const baseHost = process.env.REACT_APP_BASE_DOMAIN
export const adminHost = `${baseHost}api/admin`
export const staticBasePath = `${process.env.REACT_APP_S3_URL}media/`

export const ___PRICECOST_CONFIG_MANUAL___ =
  'https://docs.google.com/presentation/d/13XPo4JWLOcpi2DKpDy8AhIiuHJef2bXXOBeS9QJDzH4/edit?usp=sharing'
export const limitTime = 20
export const marginTime = 5

const generateNewDate = () => {
  const newDate = new Date()
  let minutes = newDate.getMinutes()
  minutes = minutes < 10 ? `0${minutes}` : minutes
  const hour = newDate.getHours()
  let date = newDate.getDate()
  date = date < 10 ? `0${date}` : date
  let month = newDate.getMonth()
  month = month < 10 ? `0${month + 1}` : month
  const year = newDate.getFullYear()
  return { year, month, minutes, hour, date }
}
export const parseDate = d => {
  const { year, month, minutes, hour, date } = generateNewDate(d)
  return `${date}/${month}/${year} - ${hour}:${minutes}`
}

export const getToday = () => {
  const { year, month, date } = generateNewDate()
  return `${year}-${month}-${date}`
}

export const timer = (time, isFormat) => {
  const templates = {
    prefix: 'Hace ',
    suffix: ' ',
    seconds: 'instantes',
    minute: '1 min',
    minutes: '%d min',
    hour: '1 hr',
    hours: ' %d hrs',
    day: '1 dia',
    days: '%d dias',
    month: 'un mes',
    months: '%d meses',
    year: 'about a year',
    years: '%d years'
  }
  const template = (t, n) =>
    templates[t] && templates[t].replace(/%d/i, Math.abs(Math.round(n)))

  if (!time) return
  let newTime = time.replace(/\.\d+/, '')
  newTime = newTime.replace(/-/, '/').replace(/-/, '/')
  newTime = newTime.replace(/T/, ' ').replace(/Z/, ' UTC')
  newTime = newTime.replace(/([+-]\d\d):?(\d\d)/, ' $1$2')
  newTime = new Date(time * 1000 || newTime)

  const now = new Date()
  const seconds = ((now.getTime() - newTime) * 0.001) >> 0
  const minutes = seconds / 60
  const hours = minutes / 60
  const days = hours / 24
  const years = days / 365

  const finalTime =
    (seconds < 45 && template('seconds', seconds)) ||
    (seconds < 90 && template('minute', 1)) ||
    (minutes < 45 && template('minutes', minutes)) ||
    (minutes < 90 && template('hour', 1)) ||
    (hours < 24 && template('hours', hours)) ||
    (hours < 42 && template('day', 1)) ||
    (days < 30 && template('days', days)) ||
    (days < 45 && template('month', 1)) ||
    (days < 365 && template('months', days / 30)) ||
    (years < 1.5 && template('year', 1)) ||
    template('years', years)
  const timeago = { minutes, seconds, hours, days }
  Object.keys(timeago).forEach(key => {
    timeago[key] = Math.round(timeago[key])
  })

  const label = `${templates.prefix}${finalTime}${templates.suffix}`
  if (isFormat) {
    return label
  }
  return { label, timeago }
}

export const secondsToTime = second => {
  let hours = Math.floor(second / 3600)
  let minutes = Math.floor((second - hours * 3600) / 60)
  let seconds = second - hours * 3600 - minutes * 60

  if (hours < 10) {
    hours = `0${hours}`
  }
  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  if (seconds < 10) {
    seconds = `0${seconds}`
  }
  return { hours, minutes, seconds }
}

export function round(value, decimals) {
  return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`)
}

const getFromStorage = item => {
  const inStorage = localStorage.getItem(`${item}`)
  return inStorage || false
}

export const generateHref = (props, url, extra) => {
  const { selected, filters } = props
  const elSelected = selected
    ? selected.reduce((acc, val) => `${acc}&id=${val.id}`, '')
    : []
  const objForExcel = Object.assign({}, filters, extra)
  let finalUrl = `${adminHost}${url}`
  const queryString = $.param(objForExcel, true)
  const token = getFromStorage('token')
  if (queryString) finalUrl += `?${queryString}`
  finalUrl = finalUrl.concat(elSelected, '&format=xlsx')
  if (token) finalUrl += `&token=${token}`
  return finalUrl
}

export function removeId(array, element) {
  return array.filter(e => e.id !== element)
}

export const manageSelect = (value, elements, isMultiple) => {
  let selectStatus
  if (!isMultiple) {
    selectStatus = elements.map(el => {
      const select = { ...el }
      select.selected = false
      if (Number(value) === select.value || value === select.value) {
        select.selected = !select.selected
      }
      return select
    })
  } else {
    selectStatus = elements.map(el => {
      const select = { ...el }
      if (Number(value) === select.value || value === select.value) {
        select.selected = !select.selected
      }
      return select
    })
  }
  return selectStatus
}

export const extractIds = data => {
  const total = data.reduce((acc, curr) => {
    curr.data.map(el => acc.push(el))
    return acc
  }, [])
  return total
}

export const getClientName = data =>
  data && data.is_company
    ? `${data.business_name} con RUC: ${data.ruc}`
    : `${data.name} ${data.last_name}`

export const searchFn = (e, props, callbackName, searchState) => {
  e.preventDefault()
  props[callbackName]({ search: searchState, page: 1 })
}

export const onchangeStateSearch = (e, context) => {
  // if(!e.target.value) context.props[callbackName]({search: e.target.value, page: 1});
  context.setState({ [e.target.name]: e.target.value })
  return context
}
export const showClient = client =>
  client && client.is_company
    ? client.tradename || client.business_name || client.name
    : client.name // client.name ||

export const arrOrderOptions = (status, orderOptionsClick) => {
  const arrTmp = []
  const objLiberateOrder = {
    onClickEl: orderOptionsClick,
    label: 'Liberar',
    options: {
      method: 'PATCH',
      entity: 'order',
      action: 'setoptions',
      data: { courier_id: null }
    }
  }
  const objCompletedOrder = {
    onClickEl: orderOptionsClick,
    label: 'Completar',
    options: {
      method: 'PATCH',
      entity: 'order',
      action: 'setoptions',
      data: { status: 'completed' }
    }
  }
  const objRelaunchOrder = {
    onClickEl: orderOptionsClick,
    label: 'Relanzar',
    options: {
      method: 'POST',
      entity: 'order',
      action: 'setoptions',
      urlConcat: '/relaunch/'
    }
  }
  if (status === 'accepted') {
    arrTmp.push(objLiberateOrder)
  }
  if (status === 'no_courier' || status === 'cancelled') {
    arrTmp.push(objRelaunchOrder)
  }
  if (status === 'accepted' || status === 'in_progress') {
    arrTmp.push(objCompletedOrder)
  }
  return arrTmp
}

export const getCouponCode = (data, couponId) => {
  if (data && data.length) {
    const client = data[0]
    console.log(client)
    const couponSelected = client.coupons.filter(el => el.id === couponId)
    return couponSelected && couponSelected.length
      ? couponSelected[0].code
      : ''
  }
  return ''
}

/**
 * Parses the latlon string to an array
 * @param {String} latlonStr
 * @returns {Array} If latlon is not a falsey value then return the
 * array with lat and lng as floats, otherwise, return an empty array
 */
export const getPosition = latlonStr => {
  if (latlonStr) {
    const [lat, lng] = latlonStr.replace(/ /g, '').split(',')
    return [parseFloat(lat), parseFloat(lng)]
  }
  return []
}

/**
 * Parses the city latlon
 * @param {Object} data
 * @returns {String} A string of latlon
 */
export const getCoordinates = data => {
  const { current_city_id, cities } = data
  const [cuttentCity] = cities.filter(city => city.city_id === current_city_id)
  return cuttentCity.latlon
}

export const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1)

export function getImageBase64(photo) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(photo)
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

/**
 * Checks if the permission requested exits in localStorage
 * @param {type} String Type of permission
 * @param {type} String Level of permission
 * @returns Boolean
 */
export function hasPermission(type = '', level = '') {
  if (localStorage.permissions) {
    const permissions = JSON.parse(localStorage.permissions)
    return Boolean(permissions[type] && permissions[type].includes(level))
  }
  return false
}

const getToken = () => {
  const { token } = localStorage
  return localStorage ? `JWT ${token}` : false
}

export const getHeaders = () => {
  let headers
  const token = getToken()
  if (token) {
    headers = {
      'Accept-Language': 'es',
      Authorization: token,
      'Content-Type': 'application/json',
      'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  }
  return headers
}
