import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'

import { InfiniteList } from '../components/03-components/InfiniteScroll'
import LTrackingMap from '../components/03-components/TrackingMap'
import CardList from '../components/04-layouts/CardList'
import CardListInfinite from '../components/04-layouts/CardListInfinite'
import { PhotoCard } from '../components/04-layouts/Card'
import OrderView from '../components/05-pages/order/detail/OrderDetail'

import {
  orderNoteUpdate,
  orderUpdate,
  updateOrderDashboardFilters,
  orderListDashboard,
  cleanOrderFilters,
  orderDashboardActions
} from '../actions/orderActions'
import { courierList, updateCourierFilters } from '../actions/courierActions'
import { getOrderTypes, resetOrderTypes } from '../actions/orderTypeActions'
import { wsEvents, closeWS, sendMessage } from '../actions/wsActions'
import {
  shouldUseTracker,
  startPollingTracker,
  stopPollingTracker,
  deleteCourierActive,
  orderClick,
  orderDetailClose,
  orderMapClick,
  courierMapClick
} from '../actions/dashboardActions'
import { getVehicleTypes } from '../actions/vehicleTypeActions'
import ActionButton from '../components/01-atoms/ActionButton'

const CONFIG = [
  {
    type: 'select',
    options: {
      multiple: true
    },
    name: 'type',
    title: 'Tipos de Pedidos',
    elements: [],
    customStyle: {
      width: '50%',
      maxWidth: '365px',
      marginLeft: '1rem',
      marginRight: 0
    }
  },
  {
    type: 'select',
    title: 'Fecha de Creación',
    options: {
      multiple: false
    },
    name: 'created_at_0',
    elements: [
      {
        value: moment()
          .startOf('day')
          .toISOString(),
        label: 'Hoy',
        selected: true
      },
      {
        value: '',
        label: 'Todos',
        selected: false
      }
    ],
    customStyle: {
      width: '50%',
      maxWidth: '365px',
      marginLeft: 0,
      marginRight: '1rem'
    }
  }
]

const DashboardContainer = ({
  dashboard,
  orderDetailUpdate,
  orderTypesList,
  vehicleTypes,
  couriers,
  history,
  getOrderTypes,
  courierList,
  shouldUseTracker,
  wsEvents,
  getVehicleTypes,
  orderListDashboard,
  resetOrderTypes,
  cleanOrderFilters,
  orderDetailClose,
  closeWS,
  stopPollingTracker,
  startPollingTracker,
  courierMapClick,
  orderNoteUpdate,
  orderUpdate,
  orderClick,
  updateOrderDashboardFilters,
  orderMapClick,
  orderDashboardActions,
  deleteCourierActive,
  updateCourierFilters
}) => {
  // Local state
  const [openToggle, setOpenToggle] = useState(false)

  // Initialize dashboard
  useEffect(() => {
    initializeDashboard()
    return () => cleanupDashboard()
  }, [])

  useEffect(() => {
    orderListDashboard(dashboard.orderFilters)
  }, [dashboard.orderFilters])

  useEffect(() => {
    if (!dashboard.isPolling && dashboard.useTracker && dashboard.mapCenter) {
      startPollingTracker(dashboard.mapCenter)
    }
  }, [dashboard.isPolling, dashboard.useTracker, dashboard.mapCenter])

  useEffect(() => {
    if (dashboard.couriers?.needRefresh) {
      // sendMessage({
      //   "stream": "couriers",
      //   "payload": {"pagination": false, "is_active": true, "is_online": true}
      // });
      courierList({
        pagination: false,
        is_active: true,
        is_online: true,
        vehicle_type: dashboard.couriers.filters.vehicle_type
      })
    }
  }, [dashboard.couriers?.needRefresh])

  const initializeDashboard = () => {
    const { orderFilters, courierActive } = dashboard
    const filters = {
      ...orderFilters,
      created_at_0: moment()
        .startOf('day')
        .toISOString()
    }
    if (courierActive) {
      filters.courier = courierActive.id
    }

    getOrderTypes()
    courierList({ pagination: false, is_active: true, is_online: true })
    shouldUseTracker()
    wsEvents()
    // sendMessage({
    //   "stream": "couriers",
    //   "payload": {"pagination": false, "is_active": true, "is_online": true}
    // });
    getVehicleTypes()
    orderListDashboard(filters)
  }

  const cleanupDashboard = () => {
    resetOrderTypes()
    cleanOrderFilters()
    orderDetailClose()
    closeWS('ORDER_DETAIL')
    stopPollingTracker()
  }

  const handleToggle = () => {
    setOpenToggle(prev => !prev)
  }

  const openDetail = () => {
    setOpenToggle(true)
  }

  const getConfig = () => {
    if (orderTypesList.length) {
      CONFIG[0].elements = parseOrderTypes(orderTypesList)
      return CONFIG
    }
    return []
  }

  const parseOrderTypes = orderTypes =>
    orderTypes.map(type => ({
      value: type.id,
      label: type.name,
      selected: false
    }))

  const parseVehicleTypes = vehicleTypes =>
    vehicleTypes.map(type => ({
      value: type.id,
      label: type.name,
      selected: false
    }))
  return (
    <div className="fh-breadcrumb row u-align-flex">
      {/* Left Sidebar: Orders List */}
      <div className="fh-column col-lg-3 dashboard-col u-hidden">
        <div style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '6px 0 0px 6px',
              margin: '0',
              width: '100%'
            }}
          >
            <ActionButton
              label="Actualizar listado de Ordenes"
              type="white"
              icon="refresh"
              onClick={() => orderListDashboard(dashboard.orderFilters.page)}
            />

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Ir a old-Dashboard</Tooltip>}
            >
              <Link to="/old-dashboard" className="btn dim btn-lg">
                <i className="fa fa-toggle-on" style={{ color: '#1AB394' }} />
              </Link>
            </OverlayTrigger>
          </div>
          {dashboard.orderData && (
            <CardListInfinite
              config={getConfig()}
              dashboardProps={dashboard}
              activeList={dashboard.orderFilters.group}
              filters={[
                {
                  group: ['searching'],
                  value: 'pending',
                  icon: 'spinner',
                  label: 'Pendientes'
                },
                {
                  group: ['accepted', 'in_progress'],
                  value: 'ongoing',
                  icon: 'check-square-o',
                  label: 'En curso'
                },
                {
                  group: ['ended', 'cancelled', 'no_courier'],
                  value: 'finished',
                  icon: 'ban',
                  label: 'Finalizados'
                }
              ]}
              count={dashboard.orderData.count}
              closeDetail={orderDetailClose}
              courierActive={dashboard.courierActive}
              deleteCourierActive={deleteCourierActive}
              updateFilters={updateOrderDashboardFilters}
              groupable
              searchFields={['title', 'subtitle1', 'subtitle2']}
              searchable
            >
              <InfiniteList
                totalPages={dashboard.orderData.num_pages}
                previusPage={dashboard.orderFilters.page}
                orderFilters={dashboard.orderFilters}
                orderOptionsClick={orderDashboardActions}
                updateOrderDashboardFilters={updateOrderDashboardFilters}
                orderMapClick={orderMapClick}
                orderClick={orderClick}
                elements={dashboard.orderData}
              />
            </CardListInfinite>
          )}
        </div>
      </div>

      {/* Main Content: Map and Order Details */}
      <div className="full-height col-lg-7 dashboard-col u-flex-expand">
        {!dashboard.orderDetailVisibility && (
          <div className="e-btn-map">
            <button
              type="button"
              onClick={handleToggle}
              className="btn btn-white btn-bitbucket"
            >
              <i className="fa fa-exchange" />
            </button>
          </div>
        )}
        <LTrackingMap
          fnHistoryPush={history.push}
          defaultCenter={dashboard.mapCenter}
          center={dashboard.mapCenter}
          fnCourierClick={courierMapClick}
          zoom={dashboard.zoom}
          courierData={dashboard.courierData || []}
          orderActive={dashboard.orderActive}
          courierActive={dashboard.courierActive}
          orderMapVisibility={dashboard.orderMapVisibility}
          onOrderUpdate={orderUpdate}
        />
        {dashboard.orderActive && dashboard.orderDetailVisibility && (
          <div className="dashboard-order-detail">
            <OrderView
              newNotes={dashboard.notes}
              newOrderDetail={orderDetailUpdate.data}
              orderClick={orderClick}
              orderUpdate={orderUpdate}
              orderNoteUpdate={orderNoteUpdate}
              data={dashboard.data}
              {...dashboard.orderActive}
              onOrderDetailClose={orderDetailClose}
            />
          </div>
        )}
      </div>

      {/* Right Sidebar: Couriers List */}
      <div
        className={`fh-column col-lg-2 dashboard-col ${
          openToggle ? 'u-show' : 'u-hide'
        }`}
        style={{ width: '350px' }}
      >
        {parseVehicleTypes(vehicleTypes).length ? (
          <CardList
            config={[
              {
                type: 'select',
                options: {
                  multiple: true
                },
                title: 'Tipos de Vehículos',
                name: 'vehicle_type',
                size: '100%',
                elements: parseVehicleTypes(vehicleTypes),
                customStyle: { width: '100%', maxWidth: '320px' }
              }
            ]}
            updateFilters={updateCourierFilters}
            filters={[
              {
                value: 'available',
                busy_level: 'free',
                icon: 'circle',
                label: 'Disponible',
                style: { color: '#1ab394' }
              },
              {
                value: 'partial',
                busy_level: 'partial',
                icon: 'circle',
                label: 'Parcial',
                style: { color: 'orange' }
              },
              {
                value: 'busy',
                busy_level: 'full',
                icon: 'circle',
                label: 'Full',
                style: { color: 'red' }
              }
            ]}
            searchFields={['title']}
            searchable
          >
            {dashboard.courierData &&
              dashboard.courierData.length &&
              dashboard.courierData.map(courier => (
                <PhotoCard
                  openDetail={courier.position ? openDetail : () => {}}
                  courierMapClick={
                    courier.position ? courierMapClick : () => {}
                  }
                  key={courier.id}
                  element={courier}
                  photo={courier.photo}
                  title={`${courier.name} ${courier.last_name}`}
                  subtitle={courier.phone}
                  filter={courier.busy_level}
                />
              ))}
          </CardList>
        ) : null}
      </div>
    </div>
  )
}

DashboardContainer.propTypes = {
  dashboard: PropTypes.object.isRequired,
  orderDetailUpdate: PropTypes.object.isRequired,
  orderTypesList: PropTypes.array.isRequired,
  vehicleTypes: PropTypes.array.isRequired,
  couriers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getOrderTypes: PropTypes.func.isRequired,
  courierList: PropTypes.func.isRequired,
  shouldUseTracker: PropTypes.func.isRequired,
  wsEvents: PropTypes.func.isRequired,
  getVehicleTypes: PropTypes.func.isRequired,
  orderListDashboard: PropTypes.func.isRequired,
  resetOrderTypes: PropTypes.func.isRequired,
  cleanOrderFilters: PropTypes.func.isRequired,
  orderDetailClose: PropTypes.func.isRequired,
  closeWS: PropTypes.func.isRequired,
  stopPollingTracker: PropTypes.func.isRequired,
  startPollingTracker: PropTypes.func.isRequired,
  orderNoteUpdate: PropTypes.func.isRequired,
  orderUpdate: PropTypes.func.isRequired,
  orderClick: PropTypes.func.isRequired,
  updateOrderDashboardFilters: PropTypes.func.isRequired,
  orderMapClick: PropTypes.func.isRequired,
  orderDashboardActions: PropTypes.func.isRequired,
  deleteCourierActive: PropTypes.func.isRequired,
  courierMapClick: PropTypes.func.isRequired,
  updateCourierFilters: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  dashboard: state.dashboard,
  orderDetailUpdate: state.orderDetail,
  orderTypesList: state.global.orderTypes.data,
  vehicleTypes: state.global.vehicleTypes.data,
  couriers: state.courierList
})

const mapDispatchToProps = {
  orderNoteUpdate,
  orderUpdate,
  orderClick,
  updateOrderDashboardFilters,
  orderMapClick,
  orderDetailClose,
  orderListDashboard,
  courierList,
  updateCourierFilters,
  wsEvents,
  courierMapClick,
  cleanOrderFilters,
  orderDashboardActions,
  deleteCourierActive,
  getOrderTypes,
  resetOrderTypes,
  closeWS,
  shouldUseTracker,
  startPollingTracker,
  stopPollingTracker,
  getVehicleTypes
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
